import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/login.vue'


Vue.use(VueRouter)
const changePush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return changePush.call(this, location).catch(err => err);
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: (to, form, next) => {
      if (null != localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
      // next()
    },
    children: [
      {
        path: '/memGroup',
        name: 'memGroup',
        component: () => import('../views/memGroup/memGroup.vue')
      },
      {
        path: '/memFunc',
        name: 'memFunc',
        component: () => import('../views/memFunc/memFunc.vue')
      },
      {
        path: '/memList',
        name: 'memList',
        component: () => import('../views/memList/memList.vue')
      },
      {
        path: '/memGrade',
        name: 'memGrade',
        component: () => import('../views/memGrade/memGrade.vue')
      },
      {
        path: '/memFriend',
        name: 'memFriend',
        component: () => import('../views/memFriend/memFriend.vue')
      },
      {
        path: '/priLetter',
        name: 'priLetter',
        component: () => import('../views/priLetter/priLetter.vue')
      },
      {
        path: '/chatRecord',
        name: 'chatRecord',
        component: () => import('../views/chatRecord/chatRecord.vue')
      },
      {
        path: '/chatMb',
        name: 'chatMb',
        component: () => import('../views/chatMb/chatMb.vue')
      },
      {
        path: '/menu',
        name: 'menu',
        component: () => import('../views/menu/menu.vue')
      },
      {
        path: '/private',
        name: 'private',
        component: () => import('../views/private/private.vue')
      },
      {
        path: '/sysConfig',
        name: 'sysConfig',
        component: () => import('../views/sysConfig/sysConfig.vue')
      },
      {
        path: '/tranConfig',
        name: 'tranConfig',
        component: () => import('../views/tranConfig/tranConfig.vue')
      },
      {
        path: '/admin',
        name: 'admin',
        component: () => import('../views/admin/admin.vue')
      },
      {
        path: '/role',
        name: 'role',
        component: () => import('../views/role/role.vue')
      },
      {
        path: '/stickers',
        name: 'stickers',
        component: () => import('../views/stickers/stickers.vue')
      },
      {
        path: '/gift',
        name: 'gift',
        component: () => import('../views/gift/gift.vue')
      },
      {
        path: '/giftCategory',
        name: 'giftCategory',
        component: () => import('../views/giftCategory/giftCategory.vue')
      },
      {
        path: '/rechargePlan',
        name: 'rechargePlan',
        component: () => import('../views/rechargePlan/rechargePlan.vue')
      },
      {
        path: '/advertisementCategory',
        name: 'advertisementCategory',
        component: () => import('../views/advertisementCategory/advertisementCategory.vue')
      },
      {
        path: '/advertisement',
        name: 'advertisement',
        component: () => import('../views/advertisement/advertisement.vue')
      },
      {
        path: '/article',
        name: 'article',
        component: () => import('../views/article/article.vue')
      },
      {
        path: '/integralOrder',
        name: 'integralOrder',
        component: () => import('../views/order/integralOrder.vue')
      },
      {
        path: '/rechargeOrder',
        name: 'rechargeOrder',
        component: () => import('../views/order/rechargeOrder.vue')
      },
      {
        path: '/subscribeOrder',
        name: 'subscribeOrder',
        component: () => import('../views/order/subscribeOrder.vue')
      },
      {
        path: '/attachment',
        name: 'attachment',
        component: () => import('../views/attachment/attachment.vue')
      },
      {
        path: '/video',
        name: 'video',
        component: () => import('../views/video/video.vue')
      },
      {
        path: '/videoCategory',
        name: 'videoCategory',
        component: () => import('../views/video/videoCategory.vue')
      },
      {
        path: '/case',
        name: 'case',
        component: () => import('../views/case/case.vue')
      },
      {
        path: '/consumeCredit',
        name: 'consumeCredit',
        component: () => import('../views/consumeCredit/consumeCredit.vue')
      },
      {
        path: '/report',
        name: 'report',
        component: () => import('../views/report/report.vue')
      },
      {
        path: '/emoj',
        name: 'emoj',
        component: () => import('../views/emoj/emoj.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = new VueRouter({
  routes
})

export default router
