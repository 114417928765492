import request from '@/utils/request'
export function menuList(data) {
    return request({
        url: '/service/menu/index',
        method: 'post',
        data: data
    })
}
export function menuListSelect(data) {
    return request({
        url: '/service/menu/index',
        method: 'get',
        params: data
    })
}
export function editMenu(data) {
    return request({
        url: '/service/menu/edit',
        method: 'post',
        data: data
    })
}
export function delMenu(data) {
    return request({
        url: '/service/menu/del',
        method: 'post',
        data: data
    })
}
export function addMenu(data) {
    return request({
        url: '/service/menu/add',
        method: 'post',
        data: data
    })
}

export function selectMenu(data) {
    return request({
        url: '/service/menu/select',
        method: 'post',
        data: data
    })
}