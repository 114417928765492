import request from '@/utils/request'
// 登录接口
export function Login(data) {
    return request({
        url: '/service/admin/login',
        method: 'post',
        data: data
    })
}
// 登出接口
export function LoginOut(data) {
    return request({
        url: '/api/fleet/logout',
        method: 'post',
        data: data
    })
}
// 修改密码接口
export function resetpwd(data) {
    return request({
        url: 'service/admin/resetpwd',
        method: 'post',
        data: data
    })
}
