<template>
  <el-container class="container">
    <el-header>
      <h1>Marriage management system</h1>
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          {{ userinfo.username }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="edit">change password</el-dropdown-item>
          <el-dropdown-item command="out">exit account</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-header>
    <el-container style="flex: 1;position: relative;">
      <el-aside width="300px">
        <el-menu class="el-menu-vertical-demo" :default-active="$route.path" router>
          <li v-for="(item, index) in list" :key="index">
            <el-submenu :index="item.id" v-if="item.sub">
              <template slot="title">
                <i class="el-icon-s-order"></i>
                <span class="title">{{ item.title }}</span>
              </template>
              <el-menu-item-group v-for="(subItem, subIndex) in item.sub">
                <el-menu-item :index="subItem.rule" class="title">{{ subItem.title }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item v-if="!item.sub" :index="item.rule">
              <i class="el-icon-s-order"></i>
              <span class="title"> {{ item.title }}</span>
            </el-menu-item>
          </li>
          <!-- <el-submenu index="0">
            <template slot="title">
              <i class="el-icon-s-order"></i>
              <span>会员管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/memGroup">会员分组</el-menu-item>
              <el-menu-item index="/memFunc">会员功能</el-menu-item>
              <el-menu-item index="/memList">会员列表</el-menu-item>
              <el-menu-item index="/memGrade">会员等级</el-menu-item>
              <el-menu-item index="/memFriend">会员朋友圈</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="/priLetter"><i class="el-icon-s-order"></i>私信管理</el-menu-item>
          <el-menu-item index="/attachment"><i class="el-icon-s-order"></i>附件管理</el-menu-item>
          <el-menu-item index="/report"><i class="el-icon-s-order"></i>举报管理</el-menu-item>
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-s-order"></i>
              <span>聊天管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/chatRecord">聊天记录</el-menu-item>
              <el-menu-item index="/chatMb">聊天模板</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-s-order"></i>
              <span>订单管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/subscribeOrder">充值订阅订单</el-menu-item>
              <el-menu-item index="/rechargeOrder">充值积分订单</el-menu-item>
              <el-menu-item index="/integralOrder">积分消耗订单</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="/rechargePlan"><i class="el-icon-s-finance"></i>充值方案</el-menu-item>
          <el-menu-item index="/consumeCredit"><i class="el-icon-s-finance"></i>积分消费方案</el-menu-item>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-s-order"></i>
              <span>网站功能</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/stickers">贴纸管理</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/emoj">表情管理</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group title="礼物管理">
              <el-menu-item index="/gift">礼物管理</el-menu-item>
              <el-menu-item index="/giftCategory">礼物分类</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group title="banner广告管理">
              <el-menu-item index="/advertisementCategory">广告分类</el-menu-item>
              <el-menu-item index="/advertisement">广告管理</el-menu-item>
            </el-menu-item-group>
            <el-menu-item index="/case">案例管理</el-menu-item>
            <el-menu-item-group title="视频管理">
              <el-menu-item index="/videoCategory">视频分类</el-menu-item>
              <el-menu-item index="/video">视频管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-s-order"></i>
              <span>权限管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/admin">管理员管理</el-menu-item>
              <el-menu-item index="/role">角色组管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="/article"><i class="el-icon-s-order"></i>文章管理</el-menu-item>
          <el-submenu index="5">
            <template slot="title">
              <i class="el-icon-s-order"></i>
              <span>系统配置</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/menu">菜单管理</el-menu-item>
              <el-menu-item index="/private">隐私政策</el-menu-item>
              <el-menu-item index="/sysConfig">系统配置</el-menu-item>
            </el-menu-item-group>
          </el-submenu> -->
        </el-menu>
      </el-aside>
      <el-main style="margin-left: 300px;">
        <keep-alive>
          <div style="background-color: white">
            <router-view />
          </div>

        </keep-alive>
      </el-main>
      <el-dialog title="change password" :visible.sync="dialogVisible" width="35%" :before-close="cancel">
        <el-form :model="form" :rules="rules" ref="form" label-width="150px" class="demo-ruleForm">
          <el-form-item label="old password" prop="old_password">
            <el-input type="password" v-model="form.old_password" autocomplete="off" show-password></el-input>
          </el-form-item>
          <el-form-item label="new password" prop="new_password">
            <el-input type="password" v-model="form.new_password" autocomplete="off" show-password></el-input>
          </el-form-item>
          <el-form-item label="enter the new password again" prop="new_password2">
            <el-input type="password" v-model="form.new_password2" autocomplete="off" show-password></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">cancel</el-button>
          <el-button type="primary" @click="save">confirm</el-button>
        </span>
      </el-dialog>
    </el-container>
  </el-container>
</template>

<script>
// import tabComponet from '@/components/tabComponets.vue';
import { LoginOut, resetpwd } from '@/api/login/login'
import { configIndex } from '@/api/website/website';
import * as api from '../api/menu'
export default {
  name: 'HomeView',
  components: {
    // tabComponet
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit('addTab', { name: to.name, title: to.meta.title });
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('removeTab', from.name);
    next();
  },
  data: function () {
    return {
      userinfo: {},
      ruleList: [],
      form: {
        old_password: '',
        new_password: '',
        new_password2: ''
      },
      dialogVisible: false,
      rules: {
        old_password: [
          { required: true, message: 'please enter your old password', trigger: 'blur' }
        ],
        new_password: [
          { required: true, message: 'please enter your new password', trigger: 'blur' }
        ],
        new_password2: [
          { required: true, validator: this.validatePass2, trigger: 'blur' }
        ],
      },
      list: [],
      configInfo: {}
    }
  },
  created() {
    this.getList()
  },
  mounted() {
    document.querySelector('.container').style.height = document.documentElement.clientHeight + 'px';
    if (localStorage.getItem('userinfo')) {
      this.userinfo = JSON.parse(localStorage.getItem('userinfo'))
      this.ruleList = JSON.parse(localStorage.getItem('rule'))
    }
  },
  methods: {
    getList() {
      api.menuListSelect({ status: 1 }).then(res => {
        if (res.code == 1) {
          this.list = res.data
          // this.total = res.data.total
        }
      })
      configIndex().then(res => {
        if (res.code == 1) {
          this.configInfo = res.data
        }
      })
    },
    validatePass2(rule, value, callback) {
      if (value === '') {
        callback(new Error('please enter your password again'));
      } else if (value !== this.form.new_password) {
        callback(new Error('the two passwords are inconsistent!'));
      } else {
        callback();
      }
    },
    cancel() {
      this.form = {
        old_password: '',
        new_password: ''
      }
      this.dialogVisible = false
    },
    save() {
      let id = JSON.parse(localStorage.getItem('userinfo')).user_id
      this.$refs.form.validate(valid => {
        if (valid) {
          resetpwd({ old_password: this.form.old_password, new_password: this.form.new_password, id }).then(res => {
            if (res.code == 1) {
              this.cancel()
              localStorage.removeItem('token')
              this.$router.push('/login')
            }
          })
        }
      })
    },
    handleCommand(command) {
      console.log(command);
      if (command == 'edit') {
        this.dialogVisible = true
      } else if (command == 'out') {
        this.$confirm('Are you sure to delete it?', 'reminder', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        }).then(() => {
          // let token = localStorage.getItem('token')
          // if (!token) {
          //   localStorage.removeItem('token')
          //   this.$router.push('/login')
          //   return
          // }
          localStorage.removeItem('token')
          this.$router.push('/login')
          // if (!token) {
          //   localStorage.removeItem('token')
          //   this.$router.push('/login')
          //   return
          // }
          // LoginOut({}).then(res => {
          //   if (res.code == 1) {
          //     localStorage.removeItem('userinfo')
          //     localStorage.removeItem('token')
          //     localStorage.removeItem('rule')
          //     this.$message.success('退出成功');
          //     this.$router.push('/login')
          //   } else {
          //     this.$message.error(res.msg);
          //   }
          // })
        }).catch(() => { });
      }

    }
  }
}
</script>

<style scoped>
.title {
  /* text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 2rem;
  display: inline-block; */
}

.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
}

.el-header {
  color: #fff;
  text-align: center;
  background: #0174f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-aside {
  color: #333;
  /* height: 100%; */
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
}

.el-main {
  color: #333;
}

.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
