import request from '@/utils/request'
// 贴纸管理
export function stickerList(data) {
    return request({
        url: '/service/sticker/index',
        method: 'get',
        params: data
    })
}
export function stickerAdd(data) {
    return request({
        url: '/service/sticker/add',
        method: 'post',
        data: data
    })
}
export function stickerDel(data) {
    return request({
        url: '/service/sticker/del',
        method: 'post',
        data: data
    })
}
export function stickerEdit(data) {
    return request({
        url: '/service/sticker/edit',
        method: 'post',
        data: data
    })
}
//礼物管理
export function giftList(data) {
    return request({
        url: '/service/gift/index',
        method: 'get',
        params: data
    })
}
export function giftAdd(data) {
    return request({
        url: '/service/gift/add',
        method: 'post',
        data: data
    })
}
export function giftDel(data) {
    return request({
        url: '/service/gift/del',
        method: 'post',
        data: data
    })
}
export function giftEdit(data) {
    return request({
        url: '/service/gift/edit',
        method: 'post',
        data: data
    })
}
//  礼物分类总列表
export function categorySelect() {
    return request({
        url: '/service/gift_cate/select',
        method: 'post',
        // data: data
    })
}
export function giftCateList(data) {
    return request({
        url: '/service/gift_cate/index',
        method: 'get',
        params: data
    })
}
export function giftCateAdd(data) {
    return request({
        url: '/service/gift_cate/add',
        method: 'post',
        data: data
    })
}
export function giftCateDel(data) {
    return request({
        url: '/service/gift_cate/del',
        method: 'post',
        data: data
    })
}
export function giftCateEdit(data) {
    return request({
        url: '/service/gift_cate/edit',
        method: 'post',
        data: data
    })
}
//网站配置
export function configIndex(data) {
    return request({
        url: '/service/config/index',
        method: 'post',
        data: data
    })
}
export function configUpdate(data) {
    return request({
        url: '/service/config/update',
        method: 'post',
        data: data
    })
}
//隐私政策
export function privacyPolicy() {
    return request({
        url: 'service/article/privacyPolicy',
        method: 'get',
        // params: data
    })
}
export function privacyPolicyupdate(data) {
    return request({
        url: 'service/article/privacyPolicy',
        method: 'post',
        data: data
    })
}